import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Layout from "../../components/layout";

export default function AboutUs() {
    return (
        <Layout title="Tentang Islah 24" topNav>
            <main className="container pt-5">
                <h1>Islah 24</h1>
                <p>
                    Ikatan Silaturahmi Santri Alumni Husnul Khotimah, kami
                    adalah anggatan lulusan ke 24.
                </p>

                <h3>Kontak</h3>
                <ul>
                    <li>
                        Bendahara
                        <br />
                        <FontAwesomeIcon
                            icon={faWhatsapp}
                            className="text-muted"
                        />{" "}
                        <a href="https://wa.me/6282116058681">
                            082116058681 (Fajrin)
                        </a>
                    </li>
                    <li>
                        Web Master
                        <br />
                        <FontAwesomeIcon
                            icon={faWhatsapp}
                            className="text-muted"
                        />{" "}
                        <a href="https://wa.me/6285161058021">
                            085161058021 (Hizbu)
                        </a>
                    </li>
                </ul>
            </main>
        </Layout>
    );
}
